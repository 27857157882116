@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(../fonts/Montserrat-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"),
    url(../fonts/Montserrat-Medium.ttf) format("ttf");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"), url(../fonts/Montserrat-Bold.ttf) format("ttf");
}

body {
  margin: 0;
  font-family: "Montserrat", "Montserrat-Medium", "Montserrat-Bold",
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loader__container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-color: #fff;
  backdrop-filter: blur(4px);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader__item {
  max-width: 320px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader__item img {
  max-width: 100%;
  width: 100px;
  height: auto;
}

.loader__animate {
  z-index: 100;
  opacity: 0.8;
  transition: all 0.5s;
}
.loader__remove_animate {
  z-index: -1;
  opacity: 0;
  transition: all 0.5s;
}
