.Header-wp {
    height: 55px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #ffffff;
    .logo-text {
        font-size: 0.9375rem;
        font-weight: bold;
        margin-left: 8px;
        color: #231F20;
    }
    .nav-items-wp {
        height: 100%;
        display: flex;
        align-items: center;
        .item__name {
            color: #231f20;
            font-size: 1rem;
            font-weight: bold;
            margin-left: 15px;
            margin-right: 15px;
            &.active {
                height: 100%;
                display: flex;
                align-items: center;
                border-bottom: 5px solid #4a5de8;
                padding-top: 4px;
            }
             &:hover {
                 height: 100%;
                 display: flex;
                 align-items: center;
                 border-bottom: 5px solid #4a5de8;
                 padding-top: 4px;
             }
        }
    }
    .profile-wp {
        .profile-dropdown {
            display: flex;
            align-items: center;
            .info-wp {
                margin-left: 10px;
                margin-right: 15px;
                .username {
                    color: #2f2f2f;
                    font-size: 0.875rem;
                    font-weight: bold;
                }
                .role-wp {
                    color: #8b93a6;
                    font-size: 0.75rem;
                    font-weight: 600;
                }
            }
        }
    }
}
